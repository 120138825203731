import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"
import { contentExists, renderFormattedText, formatDate } from "../utils/ug-utils"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Webform from 'gatsby-drupal-webform'
import Hero from "../components/hero"
import "../styles/webform.scss"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { getCrumbsObj } from "../utils/ug-utils"

const WebformPage = ({ data,pageContext }) => {
  const crumbsObj = getCrumbsObj(pageContext)
  const { webformWebform, webformPage } = data
  const { title, body, field_metatags, relationships } = webformPage.edges[0].node
  const webform = webformWebform.edges[0].node
  const ogDescription = contentExists(field_metatags) ? field_metatags.og_description : null

  return (
    <Layout>
      <Helmet
      htmlAttributes={{
        lang: 'en',
        "aria-label": "Webform Page"
      }}
        bodyAttributes={{
          class: "webform-page",
          "aria-label": "Webform Page"
        }}
        title={title}
      />
      <Seo title={title} description={ogDescription} />

      {/**** Header and Title ****/}
      <div id="rotator" className="shorter-hero">
        <div className="container ft-container">
          <div className="webform-content-area">
            <h1 className="fancy-title" aria-label={title}>{title}</h1>
          </div>
        </div>
      </div>

      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          {crumbsObj.crumbs && <Breadcrumb {...crumbsObj} aria-label="Page navigation"/>}
          <Row className="row-with-vspace site-content webform webform-full" as="article">
            <Col sm={12} className="content-area">

              { body &&
                /**** Body content ****/
                renderFormattedText(body)
              }
            </Col>
            <Col sm={12} md={8} lg={6} className="webform-area">
              <Webform
                webform={webform}
                endpoint="https://dev-aadchug.pantheonsite.io/react_webform_backend/submit"
                aria-label={`${title} Form`}
              />
            </Col>
          </Row>
        </Container>


      </div>
    </Layout>
  )
}

export default WebformPage

export const query = graphql`
  fragment WebformPage on node__webform {
    title
    drupal_internal__nid
    body {
      processed
      summary
    }
    drupal_id
    field_metatags {
      og_description
    }
  }
  query ($id: String) {
    webformPage: allNodeWebform(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...WebformPage
        }
      }
    }
    webformWebform: allWebformWebform(
    filter: {relationships: {node__webform: {elemMatch: {id: {eq: $id}}}}}
    ) {
      edges {
        node {
          title
          drupal_internal__id
          elements {
            name
            type
            attributes {
              name
              value
            }
          }
        }
      }
    }
  }
`
